@charset "UTF-8";
/**
 * Сброс стилей
 **/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Source+Sans+Pro:300,400,600,700&subset=cyrillic");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

input {
  outline: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

/**
 * Основные стили
 **/
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px; }

.container {
  min-width: 320px;
  max-width: 100%;
  padding: 0 0;
  box-sizing: border-box;
  margin: 0 auto; }

.wrap {
  min-width: 320px;
  max-width: 1200px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto; }

p {
  color: #000; }

.devider {
  display: block;
  width: 7px;
  position: relative;
  margin: 20px auto; }
  .devider p {
    text-align: center; }
    .devider p::after {
      content: "";
      width: 7px;
      height: 7px;
      background-color: #ffd345;
      display: block;
      border-radius: 50%; }
  .devider::after, .devider::before {
    content: "";
    width: 80px;
    height: 1px;
    background-color: #f4f5f9;
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-1px); }
  .devider::before {
    left: -90px; }
  .devider::after {
    right: -90px; }

.buttons {
  margin-top: 60px; }
  .buttons a {
    width: 225px;
    border-radius: 3px;
    background-color: #efbf25;
    border: 2px solid #efbf25;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    padding: 18px 0;
    letter-spacing: 0.14px;
    text-decoration: none;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    box-sizing: border-box;
    transition-duration: 500ms;
    transition-property: background-color, border-color; }
    .buttons a:hover {
      background-color: #d2a30f;
      border-color: #d2a30f; }
    .buttons a:first-child {
      margin-right: 25px; }
    .buttons a:last-child {
      background-color: transparent;
      border-color: #fff; }
    .buttons a:hover:last-child {
      border-color: #ffd345; }

@media (max-width: 560px) {
  .buttons a {
    display: block;
    margin: 10px auto; }
    .buttons a:first-child {
      margin: 15px auto; } }

.arrow-scroll {
  margin-left: 20px;
  width: 35px;
  margin-top: 10px; }

.arrow-scroll > span {
  font-family: Arial, sans-serif;
  display: block;
  width: 9px;
  height: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  margin: 5px auto 3px;
  -webkit-animation: b 1s infinite;
  animation: b 1s infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s; }

.arrow-scroll > span:first-child {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s; }

.arrow-scroll > span:last-child {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s; }

@-webkit-keyframes b {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  to {
    opacity: 1; } }

@keyframes b {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.5; }
  to {
    opacity: 1; } }

.section-wrap {
  position: relative; }
  .section-wrap__icon a {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 8%;
    transform: translate(-50%, 15px);
    width: 64px;
    height: 64px;
    border: 5px solid rgba(255, 255, 255, 0.25);
    border-radius: 50%;
    background-color: #f4f5f9; }
  .section-wrap__img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    fill: #e74c3c; }

.section-top {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  background: url("../img/section-top-bg.jpg") no-repeat center top/cover;
  background-size: cover; }
  .section-top::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); }
  .section-top__header {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0; }
  .section-top__menu {
    flex-basis: 52.5%; }
  .section-top__items {
    display: flex;
    justify-content: space-between; }
  .section-top__info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    min-height: 55vh;
    z-index: 2;
    text-align: center; }
    .section-top__info h1 {
      font-size: 24px;
      letter-spacing: 1.2px;
      font-family: 'Source Sans Pro';
      color: #f4f5f9; }
    .section-top__info span {
      display: block;
      margin: 30px 0 20px;
      font-size: 72px;
      font-weight: 700;
      font-family: 'Open Sans';
      text-transform: uppercase;
      letter-spacing: 7.2px; }
    .section-top__info p {
      max-width: 600px;
      font-size: 14px;
      line-height: 24px;
      color: #cbc6c0; }

@media (max-width: 980px) {
  .section-top__info {
    padding-top: 50px; }
    .section-top__info span {
      font-size: 42px; } }

@media (max-width: 768px) {
  .section-top__header {
    flex-wrap: wrap; }
  .section-top__logo {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 30px; }
  .section-top__menu {
    flex-basis: 100%; } }

@media (max-width: 435px) {
  .section-top__info span {
    font-size: 30px; } }

.menu ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .menu ul li {
    margin: 0 5px; }
  .menu ul a {
    padding-bottom: 17px;
    border-bottom: 1px solid transparent;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    color: #f4f5f9;
    transition-duration: 300ms;
    transition-property: color, border-bottom-color; }
    .menu ul a:hover {
      color: #efbf25;
      border-bottom-color: #efbf25; }

@media (max-width: 768px) {
  .menu ul li {
    margin: 5px; }
  .menu ul a {
    padding-bottom: 0;
    border-bottom: none; }
    .menu ul a:hover {
      border-bottom: none; } }

@media (max-width: 560px) {
  .menu ul {
    justify-content: center; } }

.about {
  display: flex;
  justify-content: space-between;
  margin: 140px auto; }
  .about .about__left {
    flex-basis: 43.2%;
    margin-right: 80px; }
    .about .about__left img {
      display: block;
      max-width: 100%; }
  .about .about__right {
    flex-basis: calc(100% - 43.2% - 80px); }
    .about .about__right .about__title {
      padding-left: 25px;
      border-left: 6px solid #ffd345;
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      color: #6a6a6a; }
    .about .about__right p {
      margin: 25px 0;
      font-size: 14px;
      line-height: 29px;
      font-family: "Source Sans Pro";
      color: #999999; }
    .about .about__right a {
      width: 225px;
      border-radius: 3px;
      background-color: #ffd345;
      border: 2px solid #ffd345;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      padding: 18px 0;
      letter-spacing: 0.14px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      box-sizing: border-box;
      transition-duration: 500ms;
      transition-property: background-color, border-color;
      margin-top: 20px;
      background-color: #ffd345;
      border-color: #ffd345; }
      .about .about__right a:hover {
        background-color: #d2a30f;
        border-color: #d2a30f; }
      .about .about__right a:hover {
        background-color: #efbf25;
        border-color: #efbf25; }

@media (max-width: 980px) {
  .about {
    margin: 50px 0; }
    .about .about__left {
      margin-right: 25px;
      align-self: center; }
    .about .about__right {
      flex-basis: calc(100% - 43.2% - 25px); } }

@media (max-width: 680px) {
  .about {
    flex-direction: column; }
    .about .about__left {
      margin-right: 0;
      margin-bottom: 30px; } }

.counts {
  position: relative;
  padding: 120px 0 110px;
  background: url("../img/counts-bg.jpg") center no-repeat;
  background-size: cover; }
  .counts::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); }
  .counts__block {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .counts__element {
    flex-basis: 20%;
    text-align: center;
    margin: 20px 0; }
    .counts__element p {
      color: #f4f5f9;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.9px;
      font-family: "Source Sans Pro";
      margin: 10px 0; }
    .counts__element span {
      color: #ffd345; }
    .counts__element .counts__number {
      font-weight: 700;
      letter-spacing: 1.5px;
      font-size: 30px; }
  .counts__circle {
    position: relative;
    margin: 0 auto 40px;
    width: 109px;
    height: 109px;
    border: 1px solid #fff;
    border-radius: 50%; }
    .counts__circle img, .counts__circle svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      fill: #fff; }
  .counts__img {
    width: 37px;
    height: 32px; }
  .counts__img2 {
    width: 40px;
    height: 40px; }
  .counts__img3 {
    width: 35px;
    height: 40px; }
  .counts__img4 {
    width: 35px;
    height: 35px; }
  .counts__img5 {
    width: 35px;
    height: 35px; }

@media (max-width: 980px) {
  .counts {
    padding: 50px 0; } }

@media (max-width: 768px) {
  .counts .counts__block {
    justify-content: center; }
  .counts .counts__element {
    flex-basis: 45%; } }

@media (max-width: 480px) {
  .counts .counts__element {
    flex-basis: 100%; } }

.works {
  margin-top: 140px; }
  .works > p {
    margin: 20px 0;
    text-align: center;
    line-height: 29px;
    color: #999999; }
  .works__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .works__devider p {
      text-align: center; }
      .works__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #ffd345;
        display: block;
        border-radius: 50%; }
    .works__devider::after, .works__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .works__devider::before {
      left: -90px; }
    .works__devider::after {
      right: -90px; }
  .works .works__title {
    font-size: 36px;
    font-weight: 700;
    font-family: "Source Sans Pro";
    letter-spacing: 3.6px;
    text-transform: uppercase;
    color: #6a6a6a; }
  .works__block {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 40px auto 0; }
  .works__element {
    flex-basis: 32%;
    margin: 0.66%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
    position: relative; }
    .works__element img {
      display: block;
      max-width: 100%; }
    .works__element a {
      display: block;
      text-decoration: none; }
      .works__element a::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(44, 145, 220, 0.7);
        opacity: 0;
        transition-duration: 500ms;
        transition-property: opacity; }
      .works__element a:hover::after, .works__element a:hover p {
        opacity: 1; }
    .works__element p {
      position: absolute;
      left: 10%;
      bottom: 35px;
      z-index: 1;
      color: #fff;
      opacity: 0;
      transition-duration: 500ms;
      transition-property: opacity; }
      .works__element p::before {
        content: "";
        width: 44px;
        height: 4px;
        background-color: #fff;
        margin-bottom: 25px;
        display: block; }
      .works__element p span {
        display: block;
        margin: 15px 0;
        font-size: 14px;
        letter-spacing: 0.7px; }
        .works__element p span:first-child {
          text-transform: uppercase;
          font-family: "Source Sans Pro";
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 1.2px; }

@media (max-width: 980px) {
  .works {
    margin-top: 50px; }
    .works_element p {
      bottom: 10px; }
      .works_element p span:first-child {
        font-size: 18px; } }

@media (max-width: 768px) {
  .works__element {
    flex-basis: 48.6%; } }

@media (max-width: 500px) {
  .works__element {
    flex-basis: 99%; } }

.partners {
  background-color: #f4f5f9;
  padding: 140px 0; }
  .partners p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .partners__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .partners__devider p {
      text-align: center; }
      .partners__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #ffd345;
        display: block;
        border-radius: 50%; }
    .partners__devider::after, .partners__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .partners__devider::before {
      left: -90px; }
    .partners__devider::after {
      right: -90px; }
  .partners .partners__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .partners__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px; }
  .partners__element {
    flex-basis: 32%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(31, 31, 31, 0.09);
    margin: 15px 0;
    height: 200px;
    display: flex;
    justify-content: center; }
    .partners__element span {
      align-self: center; }
    .partners__element img {
      max-width: 100%; }
    .partners__element i {
      background: url("../../img/bravart_logo.svg"); }

@media (max-width: 980px) {
  .partners {
    padding: 50px 0; }
    .partners__block {
      justify-content: center; }
    .partners__element {
      flex-basis: 30%;
      margin: 15px 1.5%; } }

@media (max-width: 580px) {
  .partners__element {
    flex-basis: 45%;
    margin: 15px 2.5%; } }

@media (max-width: 480px) {
  .partners__element {
    flex-basis: 100%;
    margin: 10px 0; } }

.game {
  display: flex;
  justify-content: space-between;
  margin: 140px auto 0; }
  .game .game__left {
    flex-basis: 43.2%;
    margin-right: 80px; }
    .game .game__left img {
      display: block;
      max-width: 100%; }
  .game .game__right {
    flex-basis: calc(100% - 43.2% - 80px); }
    .game .game__right .game__title {
      padding-left: 25px;
      border-left: 6px solid #ffd345;
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      color: #6a6a6a; }
    .game .game__right p {
      margin: 25px 0;
      font-size: 14px;
      line-height: 29px;
      font-family: "Source Sans Pro";
      color: #999999; }

@media (max-width: 980px) {
  .game {
    margin: 50px 0; }
    .game .game__left {
      margin-right: 25px;
      align-self: center; }
    .game .game__right {
      flex-basis: calc(100% - 43.2% - 25px); } }

@media (max-width: 680px) {
  .game {
    flex-direction: column; }
    .game .game__left {
      margin-right: 0;
      margin-bottom: 30px; } }

.keep {
  background-color: #f4f5f9;
  padding: 140px 0; }
  .keep p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: #999999; }
  .keep__devider {
    display: block;
    width: 7px;
    position: relative;
    margin: 20px auto; }
    .keep__devider p {
      text-align: center; }
      .keep__devider p::after {
        content: "";
        width: 7px;
        height: 7px;
        background-color: #ffd345;
        display: block;
        border-radius: 50%; }
    .keep__devider::after, .keep__devider::before {
      content: "";
      width: 80px;
      height: 1px;
      background-color: #999999;
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-1px); }
    .keep__devider::before {
      left: -90px; }
    .keep__devider::after {
      right: -90px; }
  .keep .keep__title {
    color: #6a6a6a;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase; }
  .keep__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 60px; }
  .keep__left {
    flex-basis: 40.8%;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 2px/5px;
    padding: 30px 95px;
    box-sizing: border-box; }
  .keep__right {
    flex-basis: 56.8%;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 3px/5px;
    padding: 0 95px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .keep__right .keep__form {
      flex-basis: 100%; }
  .keep__info {
    margin: 55px 0; }
    .keep__info p {
      text-align: left;
      color: #999999;
      font-size: 18px;
      line-height: 24px;
      margin: 20px 0; }
      .keep__info p:first-child {
        font-size: 24px;
        line-height: 24px;
        font-weight: 600;
        font-family: "Source Sans Pro";
        color: #6a6a6a;
        text-transform: uppercase; }
      .keep__info p a {
        color: #999999;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none; }
  .keep__form .keep__input, .keep__form .keep__textarea {
    position: relative;
    margin: 20px 0; }
    .keep__form .keep__input input, .keep__form .keep__input textarea, .keep__form .keep__textarea input, .keep__form .keep__textarea textarea {
      color: #999999;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      font-weight: 600;
      border: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 7px 0;
      box-shadow: none; }
      .keep__form .keep__input input:focus + label, .keep__form .keep__input input:valid + label, .keep__form .keep__input textarea:focus + label, .keep__form .keep__input textarea:valid + label, .keep__form .keep__textarea input:focus + label, .keep__form .keep__textarea input:valid + label, .keep__form .keep__textarea textarea:focus + label, .keep__form .keep__textarea textarea:valid + label {
        transform: translateY(-9px);
        font-size: 12px; }
    .keep__form .keep__input textarea, .keep__form .keep__textarea textarea {
      resize: none;
      outline: none;
      height: 100px; }
    .keep__form .keep__input label, .keep__form .keep__textarea label {
      color: #999999;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition-duration: 200ms; }
  .keep__form .keep__button {
    margin: 25px 0; }
    .keep__form .keep__button button {
      width: 225px;
      border-radius: 3px;
      background-color: #ffd345;
      border: 2px solid #ffd345;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      padding: 18px 0;
      letter-spacing: 0.14px;
      text-decoration: none;
      display: inline-block;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      box-sizing: border-box;
      transition-duration: 500ms;
      transition-property: background-color, border-color;
      cursor: pointer;
      width: 157px;
      padding: 14px 0;
      background-color: #ffd345;
      border-color: #ffd345; }
      .keep__form .keep__button button:hover {
        background-color: #d2a30f;
        border-color: #d2a30f; }
      .keep__form .keep__button button:hover {
        background-color: #efbf25;
        border-color: #efbf25; }

@media (max-width: 980px) {
  .keep {
    padding-bottom: 50px; }
    .keep__left {
      padding: 30px; }
    .keep__right {
      padding: 0 30px; } }

@media (max-width: 768px) {
  .keep__info {
    margin: 25px 0; }
  .keep__left {
    flex-basis: 100%;
    margin: 15px 0; }
  .keep__right {
    flex-basis: 100%;
    margin: 15px 0;
    padding: 30px; } }

.footer {
  background-color: #202020;
  padding: 35px 0; }
  .footer__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer__right p {
    font-size: 14px;
    line-height: 24px;
    color: #999999;
    font-weight: 600; }

@media (max-width: 580px) {
  .footer__wrap {
    justify-content: center;
    text-align: center; }
  .footer__left {
    flex-basis: 100%; }
  .footer__right {
    flex-basis: 100%; }
  .footer__socials {
    justify-content: center; } }

.arrow-up {
  width: 53px;
  height: 53px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 30px;
  bottom: 30px;
  background-color: #ffd345;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10; }
  .arrow-up a {
    display: block;
    width: 100%;
    height: 100%; }
  .arrow-up img, .arrow-up svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .arrow-up .arrow-up__img {
    width: 12px;
    height: 12px;
    fill: #fff; }

.socials {
  display: flex;
  align-items: center; }
  .socials li {
    margin-right: 20px; }
  .socials__img {
    fill: #999999;
    width: 20px;
    height: 25px; }
    .socials__img:hover {
      fill: #ffd345; }
