.footer {
  background-color: $service-bg;
  padding: 35px 0;

  &__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__right {

    p {
      font-size: 14px;
      line-height: 24px;
      color: $advantages-gray;
      font-weight: 600;
    }
  }
}

@media (max-width: 580px) {

  .footer {

    &__wrap {
      justify-content: center;
      text-align: center;
    }

    &__left {
      flex-basis: 100%;
    }

    &__right {
      flex-basis: 100%;
    }

    &__socials {
      justify-content: center;
    }
  }
}