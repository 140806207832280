.about {
  display: flex;
  justify-content: space-between;
  margin: 140px auto;

  .about__left {
    flex-basis: 43.2%;
    margin-right: 80px;

    img {
      display: block;
      max-width: 100%;
    }

  }

  .about__right {
    flex-basis: calc(100% - 43.2% - 80px);

    .about__title {
      padding-left: 25px;
      border-left: 6px solid $toy;
      margin-top: 0;
      margin-bottom: 30px;

      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      color: $gray;
    }

    p {
      margin: 25px 0;
      font-size: 14px;
      line-height: 29px;
      font-family: "Source Sans Pro";
      color: $advantages-gray;

    }

    a {
      @include btn() {
        margin-top: 20px;
        background-color: $toy;
        border-color: $toy;
      };
      &:hover{
          background-color: $toyhover;
          border-color: $toyhover;
        }
    }

  }
}

@media (max-width: 980px) {

  .about {
    margin: 50px 0;

    .about__left {
      margin-right: 25px;
      align-self: center;
    }

    .about__right {
      flex-basis: calc(100% - 43.2% - 25px);
    }

  }

}

@media (max-width: 680px) {

  .about {
    flex-direction: column;

    .about__left {
      margin-right: 0;
      margin-bottom: 30px;
    }

  }

}