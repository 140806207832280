.works{
  margin-top: 140px;

  > p {
    margin: 20px 0;
    text-align: center;
    line-height: 29px;
    color: $advantages-gray;
  }

  &__devider {
      @include devider($advantages-gray);
    }

    .works__title {
     font-size: 36px;
     font-weight: 700;
     font-family: "Source Sans Pro";
     letter-spacing: 3.6px;
     text-transform: uppercase;
     color: $gray;
    }


   &__block {
     display: flex;
     flex-wrap: wrap;
     max-width: 1920px;
     margin: 40px auto 0;
  }

   &__element {
     flex-basis: 32%; 
     // box-sizing: border-box;
     margin: 0.66%;
     box-shadow: 0 2px 5px rgba(0,0,0,.25);
     position: relative;

     img{
        display: block;
        max-width: 100%;
      }

      a{
        display: block;
        text-decoration: none;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba(44, 145, 220, 0.7);
          opacity: 0;
          transition-duration: 500ms;
          transition-property: opacity;
        }
        &:hover {
            &::after, p {
              opacity: 1;
            }
        }
      }

      p{
        position: absolute;
        left: 10%;
        bottom: 35px;
        z-index: 1;
        color: $white;
        opacity: 0;
        transition-duration: 500ms;
        transition-property: opacity;

        &::before {
          content: "";
          width: 44px;
          height: 4px;
          background-color: $white;
          margin-bottom: 25px;
          display: block;
        }

      span {
         display: block;
         margin: 15px 0;
         font-size: 14px; 
         letter-spacing: 0.7px;

        &:first-child {
          text-transform: uppercase;
          font-family: "Source Sans Pro";
          font-size: 24px;
          font-weight: 600;
          letter-spacing: 1.2px;
        }
      }
    }
  }
}

@media (max-width: 980px) {

  .works {
   	margin-top: 50px;

      &_element {

	      p {
	        bottom: 10px;

	        span {
		         &:first-child {
		           font-size: 18px;
		         }
	        	}
	      }
   	}
	}
}

@media (max-width: 768px) {

  .works {

	   &__element {
	     flex-basis: 48.6%;
	   }
  }
}

@media (max-width: 500px) {

  .works {

	   &__element {
	     flex-basis: 99%;
	   }
  }
}