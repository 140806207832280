.socials {
  display: flex;
  align-items: center;

  li {
    margin-right: 20px;
  }

  &__img {
    fill: $advantages-gray;
    width: 20px;
    height: 25px;

    &:hover {
      fill: $toy;
    }
  }
}

