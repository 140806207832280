.section-top{
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	min-height: 100vh;
	background: url('../img/section-top-bg.jpg') no-repeat center top / cover;
	background-size: cover;
	
	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1; 

		display: block;
		width: 100%;
		height: 100%; 
		background-color: rgba(0, 0, 0, 0.7);
	}

	&__header{
		position: relative;
		z-index: 2;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 40px 0;
	}

	&__menu{
		flex-basis: 52.5%;
	}

	&__items{
		display: flex;
		justify-content: space-between;
	}

	&__info{
		// position: relative;
		// z-index: 2;
		// padding: 180px 0 300px;
		// text-align: center;

		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 50px;
		min-height: 55vh;
		z-index: 2;
		text-align: center;

		h1{
			font-size: 24px;
			letter-spacing: 1.2px;
			font-family: 'Source Sans Pro';
			color: $white-gray;
		}

		span{
			display: block;
			margin: 30px 0 20px;
			font-size: 72px;
			font-weight: 700;
			font-family: 'Open Sans';
			text-transform: uppercase;
			letter-spacing: 7.2px;
		}

		p{
			// margin: 20px auto;
			max-width: 600px;
			font-size: 14px;
			line-height: 24px;
			color: $gray-light;
		}
	}

}

@media (max-width: 980px) {
	.section-top{

		&__info{
			padding-top: 50px;

			span{
				font-size: 42px;
			}
		}
	}
}

@media (max-width: 768px){
	.section-top{
		&__header{
			flex-wrap: wrap;
		}
		&__logo{
			flex-basis: 100%;
			text-align: center;
			margin-bottom: 30px;
		}
		&__menu{
			flex-basis: 100%;
		}
	}
}

@media (max-width: 435px){
	.section-top{
		&__info{
			span{
				font-size: 30px;
			}
		}
	}
}