.menu{
	ul{
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		li{
			margin: 0 5px;
		}

		a{
			padding-bottom: 17px;
			border-bottom: 1px solid transparent;

			font-size: 14px;
			line-height: 16px;
			text-decoration: none;
			color: $white-gray;

			transition-duration: 300ms;
			transition-property: color, border-bottom-color;

			&:hover{
				color: $toyhover;
				border-bottom-color: $toyhover;
			}
		}
	}
}


@media (max-width: 768px){
	.menu{
		ul{
			li{
			margin: 5px;
			}

			a{
				padding-bottom: 0;
				border-bottom: none;

				&:hover{
					border-bottom: none;
				}
			}
		}
	}
}

@media (max-width: 560px){
	.menu{

		ul{
			justify-content: center;
		}
	}
}