.counts {
  position: relative;
  padding: 120px 0 110px;
  background: url('../img/counts-bg.jpg') center no-repeat;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__block {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__element {
    flex-basis: 20%;
    text-align: center;
    margin: 20px 0;

		p {
		   color: $white-gray;
		   font-size: 18px;
		   line-height: 30px;
		   letter-spacing: 0.9px;
		   font-family: "Source Sans Pro";
		   margin: 10px 0;
		}

    span{
      color: $toy;
    }

		.counts__number {
		      font-weight: 700;
		      letter-spacing: 1.5px;
		      font-size: 30px;
		    }
	}

   &__circle {
      position: relative;
      margin: 0 auto 40px;
      width: 109px;
      height: 109px;
      border: 1px solid $white;
      border-radius: 50%;

	      img, svg {
	        position: absolute;
	        left: 50%;
	        top: 50%;
	        transform: translate(-50%, -50%);
	        fill: $white;
	      }
		}

	&__img {
	  width: 37px;
	  height: 32px;
	}

	&__img2 {
	  width: 40px;
	  height: 40px;
	}

	&__img3 {
	  width: 35px;
	  height: 40px;
	}

	&__img4 {
	  width: 35px;
	  height: 35px;
	}

	&__img5 {
	  width: 35px;
	  height: 35px;
	}
}


@media (max-width: 980px) {

  .counts {
    padding: 50px 0;
  }
}

@media (max-width: 768px) {

  .counts {

     .counts__block {
       justify-content: center;
     }

     .counts__element {
       flex-basis: 45%;
     }
   }
}

@media (max-width: 480px) {

  .counts {

     .counts__element {
       flex-basis: 100%;
     }
   }
}