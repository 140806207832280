.partners {
  background-color: $white-gray;
  padding: 140px 0;

  p {
    font-size: 14px;
    line-height: 29px;
    margin: 20px 0;
    text-align: center;
    color: $advantages-gray;
  }

  &__devider {
    @include devider($advantages-gray);
  }

  .partners__title {
    color: $gray;
    font-size: 36px;
    letter-spacing: 3.6px;
    font-family: "Source Sans Pro";
    font-weight: 700;
    text-transform: uppercase;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  &__element {
    flex-basis: 32%;
    background-color: $white;
    box-shadow: 0 1px 3px rgba(31,31,31,.09);
    margin: 15px 0;
    height: 200px;
    display: flex;
    justify-content: center;

    span {
      align-self: center;
    }

    img {
      max-width: 100%;
    }

    i{
      background: url("../../img/bravart_logo.svg");
    }
  }
}

@media (max-width: 980px) {

  .partners {
    padding: 50px 0;

    &__block {
      justify-content: center;
    }

    &__element {
      flex-basis: 30%;
      margin: 15px 1.5%;
    }
  }
}

@media (max-width: 580px) {

  .partners {

    &__element {
      flex-basis: 45%;
      margin: 15px 2.5%;
    }
  }
}

@media (max-width: 480px) {

  .partners {

    &__element {
      flex-basis: 100%;
      margin: 10px 0;
    }
  }
}