.section-wrap{
	position: relative;

	&__icon{
		a{
			position: absolute;
			z-index: 2;
			left: 50%;
			bottom: 8%;
			transform: translate(-50%, 15px);
			width: 64px;
			height: 64px;
			border: 5px solid rgba(255, 255, 255, 0.25);
			border-radius: 50%;
			background-color: $white-gray;
		}
	}

	&__img{
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 30px;
		height: 30px;
		fill: $red;
		
	}
}