.arrow-scroll {
  margin-left: 20px;
  width: 35px;
  margin-top: 10px;
}
.arrow-scroll > span {
  font-family: Arial, sans-serif;
  display: block;
  width: 9px;
  height: 9px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  margin: 5px auto 3px;
  -webkit-animation: b 1s infinite;
  animation: b 1s infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.arrow-scroll > span:first-child {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.arrow-scroll > span:last-child {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes b {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@keyframes b {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
