.game {
  display: flex;
  justify-content: space-between;
  margin: 140px auto 0;

  .game__left {
    flex-basis: 43.2%;
    margin-right: 80px;

    img {
      display: block;
      max-width: 100%;
    }

  }

  .game__right {
    flex-basis: calc(100% - 43.2% - 80px);

    .game__title {
      padding-left: 25px;
      border-left: 6px solid $toy;
      margin-top: 0;
      margin-bottom: 30px;

      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 3.6px;
      text-transform: uppercase;
      color: $gray;
    }

    p {
      margin: 25px 0;
      font-size: 14px;
      line-height: 29px;
      font-family: "Source Sans Pro";
      color: $advantages-gray;

    }

    a {

    }

  }
}

@media (max-width: 980px) {

  .game {
    margin: 50px 0;

    .game__left {
      margin-right: 25px;
      align-self: center;
    }

    .game__right {
      flex-basis: calc(100% - 43.2% - 25px);
    }

  }

}

@media (max-width: 680px) {

  .game {
    flex-direction: column;

    .game__left {
      margin-right: 0;
      margin-bottom: 30px;
    }

  }

}